import React from 'react';

import ShowcasePage from '../../../components/ShowcasePage';

const Showcase = () => (
  <ShowcasePage
    totalItems={14}
    s3Path={`portfolio/pregnant/9/`}
  />
)

export default Showcase;
